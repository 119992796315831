import {type FC} from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  Heading,
  Highlight,
  Link,
  Text,
} from '@chakra-ui/react'
import {
  ArrowLeftIcon,
  LogoIcon,
  PropertyHouseIcon,
  MortgageExpertIcon,
} from '@imagine-finance/component-library'
import {BrowserRouter, Routes, Route} from 'react-router-dom'

import HousesSunset from '../../../assets/images/houses-sunset.webp'
import {IntentCard} from './IntentCard'

export const Homepage: FC = () => (
  <Box
    minHeight="100dvh"
    backgroundImage={HousesSunset}
    backgroundRepeat="no-repeat"
    backgroundPosition="center"
    backgroundSize="cover"
  >
    <Box
      marginBottom={{base: '3', md: '6'}}
      paddingX={{base: '4', md: '10'}}
      paddingTop={{base: '3', md: '6'}}
    >
      <LogoIcon
        boxSize={{base: '40px', md: '62px'}}
        color="white"
        role="presentation"
      />
    </Box>

    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Container maxWidth="sm">
              <Link href="https://www.generationhome.com">
                <Button
                  leftIcon={<ArrowLeftIcon />}
                  variant="tertiary"
                  colorScheme="dark"
                >
                  Back
                </Button>
              </Link>

              <Heading
                as="h1"
                size="h2"
                variant="brand"
                color="white"
                marginTop="4"
                marginBottom="12"
              >
                <Highlight
                  query="Welcome"
                  styles={{
                    backgroundColor: 'brand.red.400',
                    color: 'inherit',
                    px: '2',
                    boxDecorationBreak: 'clone',
                  }}
                >
                  Welcome to Gen H
                </Highlight>
              </Heading>

              <Grid templateColumns="1fr 1fr" gap="4">
                <IntentCard href="/users/sign_in">
                  <PropertyHouseIcon boxSize="7" marginBottom="2" />
                  I’m an existing customer
                </IntentCard>

                <IntentCard href="/brokers">
                  <MortgageExpertIcon boxSize="7" marginBottom="2" />
                  I’m a mortgage broker
                </IntentCard>
              </Grid>
            </Container>
          }
        />

        <Route
          path="/direct-closed"
          element={
            <Container size="container.md">
              <Link href="https://www.generationhome.com">
                <Button
                  leftIcon={<ArrowLeftIcon />}
                  variant="tertiary"
                  colorScheme="dark"
                >
                  Back
                </Button>
              </Link>

              <Heading
                as="h1"
                size="h2"
                variant="brand"
                color="white"
                marginTop="4"
                marginBottom="12"
              >
                <Highlight
                  query="Get started"
                  styles={{
                    backgroundColor: 'brand.red.400',
                    color: 'inherit',
                    px: '2',
                    boxDecorationBreak: 'clone',
                  }}
                >
                  Get started with Gen H
                </Highlight>
              </Heading>

              <Box
                paddingX="4"
                paddingY="6"
                color="white"
                backgroundColor="supporting.900"
                borderRadius="md"
              >
                <Text marginBottom="4">
                  We’re no longer accepting direct mortgage applications, but
                  don’t worry – you can still apply for a Gen H mortgage through
                  a broker.
                </Text>{' '}
                <Text>
                  You can check out{' '}
                  <Link
                    href="https://www.unbiased.co.uk/"
                    rel="noopener"
                    fontWeight="bold"
                  >
                    unbiased.co.uk
                  </Link>{' '}
                  to find the right mortgage broker for you. Some brokers may
                  charge a fee.
                </Text>
              </Box>
            </Container>
          }
        />
      </Routes>
    </BrowserRouter>
  </Box>
)
