import {type FC, type PropsWithChildren} from 'react'
import {Box, Flex, Link} from '@chakra-ui/react'

export const IntentCard: FC<PropsWithChildren<{href: string}>> = ({
  children,
  href,
}) => (
  <Box>
    <Link
      href={href}
      textDecoration="none"
      textAlign="center"
      _hover={{textDecoration: 'none'}}
    >
      <Flex
        paddingX="4"
        paddingY="6"
        minWidth="160px"
        border="2px solid black"
        borderRadius="4"
        backgroundColor="white"
        fontWeight="bold"
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Flex>
    </Link>
  </Box>
)
