import {StrictMode} from 'react'
import {ChakraProvider} from '@chakra-ui/react'
import {createRoot} from 'react-dom/client'
import invariant from 'tiny-invariant'

import {theme} from '@imagine-finance/component-library'

import {Homepage} from '../app/Homepage/Homepage'

document.addEventListener('DOMContentLoaded', () => {
  const rootElement = document.getElementById('root')
  invariant(rootElement, 'Root element not present')
  const root = createRoot(rootElement)

  root.render(
    <StrictMode>
      <ChakraProvider theme={theme}>
        <Homepage />
      </ChakraProvider>
    </StrictMode>,
  )
})
